/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

const Command = ({ command, refreshCommands }) => {
    const updatedCommand = async () => {
        try {
            await fetch('/.netlify/functions/commands', {
                method: 'PUT',
                body: JSON.stringify({ ...command}),
            });
            refreshCommands();
        } catch (error) {
            console.log(error);
        }
    };

    const deleteCommand = async () => {
        try {
            await fetch('/.netlify/functions/commands', {
                method: 'DELETE',
                body: JSON.stringify({ id: command.id }),
            });
            refreshCommands();
        } catch (error) {
            console.log(error);
        }
    };

    var permited = "Everyone";
    if (command.permissions !== 5) {
        permited = "Developer Only"
    }

    var usage =""
    if (command.universalCMD) {
        usage = "Everywhere"
    } else if (command.twitch) {
        usage = "Twitch"
    } else {
        usage = "Discord"
    }

    var frontimage = "";
    var backimage = "";
    if (command.imageURL !== false) {
        frontimage = command.imageURL;
        backimage = command.imageURL;
    } else {
        frontimage = "https://media.giphy.com/media/OK5LK5zLFfdm/giphy.gif";
        backimage = "https://media.giphy.com/media/OK5LK5zLFfdm/giphy.gif";
    }

    var func = JSON.stringify(JSON.parse(command.typeCMD).isFunction);

    if (!command.hidden) {
    return (
        <div class="card">
            <div class="card_front">
                <div class="card_image"> 
                    <img src="https://media.giphy.com/media/OK5LK5zLFfdm/giphy.gif" />
                </div>
                <div class="card_title title-white">
                    <p>{command.command}</p>
                </div>
                <div class="card_desc desc-white">
                    <p>{command.description}</p>
                </div>
            </div>
            <div class="card_back">
                <div class="card_image"> 
                    <img src="https://media.giphy.com/media/OK5LK5zLFfdm/giphy.gif" />
                </div>
                <div class="card_title title-white">
                    <p>More Info</p>
                </div>
                <div class="card_desc desc-white">
                <p>Usage: {usage}</p><p>Static: {func}</p><p>Permissions: {permited}</p>
                </div>
            </div>
        </div>
    )
    };
};

export default Command;