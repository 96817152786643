import "./App.css";
import { useEffect, useState } from "react";
import Commands from "./components/Command";
import CommandList from "./components/CommandList";


function App() {
  const [commands, setCommands] = useState([]);
  
  const loadCommands = async () => {
    try {
      const res = await fetch('/.netlify/functions/commands');
      const commands = await res.json();
      setCommands(commands);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    loadCommands();
  }, []);

  return (
    <CommandList commands={commands} refreshCommands={loadCommands} />
  );
}

export default App;