import React from "react";
import Command from "./Command";

const CommandList = ({ commands, refreshCommands }) => {
    return (
        <div className="commands-list">
        {commands.map((command) => 
            <Command
            key={command.id}
            command={command}
            refreshCommands={refreshCommands}
            />
        )}
        </div>
    );
};

export default CommandList;